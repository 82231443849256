<p-toast></p-toast>
<p-confirmDialog [style]="{ width: '50vw' }"></p-confirmDialog>
<div class="card h-full">
  <div class="card-container justify-content-start p-3">
    <div class="block border-round mb-3">

      <div class="flex justify-content-between align-items-center">
        <div class="actions-container" id="actions">
          <button (click)="navigateTo('future')" class="future-events-button"
            [ngClass]="{ active: currentView === 'future' }" type="button">
            Actuales
          </button>
          <button (click)="navigateTo('past')" class="past-events-button" [ngClass]="{ active: currentView === 'past' }"
            type="button">
            Pasadas
          </button>
          <button (click)="navigateTo('all')" class="all-events-button" [ngClass]="{ active: currentView === 'all' }"
            type="button">
            Todas
          </button>
        </div>
      
        <div class="flex align-items-center gap-3">
          <p-multiSelect 
            [options]="ticketingProviders" 
            [(ngModel)]="selectedProviders"
            [defaultLabel]="'Sincronizar canales'"
            optionLabel="providerName"
            optionValue="providerId"
            [style]="{'width': '200px'}"
            class="provider-select">
          </p-multiSelect>
      
          <button 
            pButton 
            type="button" 
            icon="pi pi-sync" 
            [disabled]="!selectedProviders?.length"
            (click)="syncSelectedProviders()"
            class="p-button-text sync-button">
          </button>
      
          <span class="p-input-icon-left">
            <i class="pi pi-search"></i>
            <input type="text" 
                   pInputText 
                   [value]="searchTerm"
                   (input)="onSearch($event)"
                   placeholder="Buscar por evento"
                   class="p-inputtext-sm">
          </span>
        </div>
      </div>
  </div>

    <div class="table-container">
      <p-table 
        styleClass="p-datatable-sm"
        [value]="loading ? skeletonRows : events" 
        [paginator]="true" 
        [rows]="pageSize" 
        [totalRecords]="totalRecords"
        [scrollHeight]="'max(calc(100vh - 200px))'"
        [scrollable]="true"
        [first]="first" 
        [lazy]="true" 
        (onLazyLoad)="onPageChange($event)"
        selectionMode="single" 
        [(selection)]="selectedEvent"
        (onRowSelect)="openEvent($event)"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} eventos"
        [rowsPerPageOptions]="rowsPerPageOptions"
        (onSort)="onSort($event)"
        [sortField]="sortField"
        [sortOrder]="sortOrder">
        <ng-template pTemplate="header">
          <tr>
            <th pSortableColumn="name">
              Nombre <p-sortIcon field="name"></p-sortIcon>
            </th>
            <th pSortableColumn="location">
              Localización <p-sortIcon field="location"></p-sortIcon>
            </th>
            <th pSortableColumn="tickets">
              Entradas vendidas / totales <p-sortIcon field="tickets"></p-sortIcon>
            </th>
            <th pSortableColumn="startDate">
              Fecha <p-sortIcon field="startDate"></p-sortIcon>
            </th>
            <th pSortableColumn="state">
              Estado <p-sortIcon field="state"></p-sortIcon>
            </th>
            <th pSortableColumn="provider">
              Canal <p-sortIcon field="provider"></p-sortIcon>
            </th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template let-event pTemplate="body">
          <tr [pSelectableRow]="event">
            <td>
              <div *ngIf="loading">
                <p-skeleton width="100%" height="24px"></p-skeleton>
              </div>
              <ng-container *ngIf="!loading">{{ event.name }}</ng-container>
            </td>
            <td>
              <div *ngIf="loading">
                <p-skeleton width="100%" height="24px"></p-skeleton>
              </div>
              <ng-container *ngIf="!loading">{{ formatAddress(event) }}</ng-container>
            </td>
            <td>
              <div *ngIf="loading">
                <p-skeleton width="80%" height="24px"></p-skeleton>
              </div>
              <div *ngIf="!loading" class="flex flex-row align-items-center">
                <span *ngIf="event.ticketsNumber">
                  {{ event.ticketsSold }} / {{ event.ticketsNumber }}
                </span>
                <button pButton icon="pi pi-external-link" pTooltip="Ver asistentes"
                  [routerLink]="[event.id, 'attendees']" class="p-button-text row-button"></button>
              </div>
            </td>
            <td>
              <div *ngIf="loading">
                <p-skeleton width="100%" height="24px"></p-skeleton>
              </div>
              <p *ngIf="!loading && event.startDate">{{ getLocalDate(event.startDate) }}</p>
            </td>
            <td>
              <div *ngIf="loading">
                <p-skeleton width="80px" height="24px"></p-skeleton>
              </div>
              <div *ngIf="!loading">

                <div *ngIf="event.state === 'DRAFT'" class="draft-chip">
                  <span>Borrador</span>
                </div>

                <ng-container [ngSwitch]="getEventTimeStatus(event)" *ngIf="event.state !== 'DRAFT'">

                  <div *ngSwitchCase="EventTimeStatus.HAPPENING_NOW" class="happening-now-chip">
                    <span>En curso</span>
                  </div>
                  
                  <div *ngSwitchCase="EventTimeStatus.PAST" class="finished-chip">
                    <span>Finalizado</span>
                  </div>

                  <div *ngSwitchCase="EventTimeStatus.FUTURE" class="scheduled-chip">
                    <span>Programado</span>
                  </div>

                </ng-container>
              </div>
              <td>
                <div *ngIf="loading">
                    <p-skeleton width="32px" height="32px"></p-skeleton>
                </div>
                <div *ngIf="!loading" class="flex align-items-center">
                    <img [src]="event.provider?.logoUrl" 
                         [alt]="event.provider?.name"
                         [pTooltip]="event.provider?.name"
                         tooltipPosition="top"
                         class="provider-logo cursor-pointer">
                </div>
            </td>
            <td>
              <div *ngIf="loading">
                <p-skeleton width="40px" height="24px"></p-skeleton>
              </div>
              <p-inputSwitch *ngIf="!loading && event.state !== 'DRAFT'" (onChange)="handleChange(event.id, $event)"
                [(ngModel)]="event.active"></p-inputSwitch>
            </td>
            <td>
              <div *ngIf="loading">
                <p-skeleton width="80px" height="24px"></p-skeleton>
              </div>
              <ng-container *ngIf="!loading">
                <div class="flex" *ngIf="event.state !== 'DRAFT'">
                  <button pButton pRipple type="button" icon="pi pi-link" pTooltip="Copiar enlace" tooltipPosition="top"
                    class="p-button-rounded p-button-text" (click)="copyLink(event.url)"></button>
                  <button pButton pRipple type="button" icon="pi pi-clone" pTooltip="Clonar experiencia"
                    tooltipPosition="top" class="p-button-rounded p-button-text" (click)="clone(event.id)"></button>
                </div>
                <div class="flex justify-content-center" *ngIf="event.state === 'DRAFT'">
                  <button pButton class="p-button-text p-button-success" type="button" label="Publicar"
                    (click)="publish(event.id)"></button>
                </div>
              </ng-container>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
  <button [routerLink]="['/events/create']" class="floating-button" pButton pRipple type="button">
    <i class="pi pi-plus"></i>
  </button>
</div>