<div class="stepsdemo-content table-container">
  <p-card>
    <ng-template pTemplate="title">
      Descripción de la experiencia
      <span *ngIf="isExternalEvent" class="text-sm text-gray-500 ml-2">(Evento externo - Solo lectura)</span>
    </ng-template>
    <ng-template pTemplate="subtitle">
      Introduce los detalles de tu experiencia
    </ng-template>
    <ng-template pTemplate="content">
      <form [formGroup]="descriptionForm">
        <div class="field">
          <label for="name">Nombre *</label>
          <input id="name" formControlName="name" type="text" pInputText
            class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
            [readonly]="isExternalEvent" />
          <div *ngIf="
              !isExternalEvent &&
              this.descriptionForm.get('name')?.invalid &&
              (this.descriptionForm.get('name')?.dirty ||
                this.descriptionForm.get('name')?.touched)
            " id="name-help">
            <small class="p-error block" *ngIf="this.descriptionForm.controls.name.errors?.required">
              El nombre es obligatorio.
            </small>
            <small class="p-error block" *ngIf="this.descriptionForm.controls.name.errors?.minlength">
              El nombre tiene que tener al menos
              {{this.descriptionForm.controls.name.errors?.minlength.requiredLength}}
              caracteres.
            </small>
            <small class="p-error block" *ngIf="this.descriptionForm.controls.name.errors?.maxlength">
              El nombre no puede tener más de
              {{this.descriptionForm.controls.name.errors?.maxlength.requiredLength}}
              caracteres.
            </small>
          </div>
        </div>
        <div class="field">
          <label for="description">Descripción</label>
          <p-editor formControlName="description" [style]="{ height: '200px' }" [readonly]="isExternalEvent">
            <ng-template pTemplate="header">
              <span class="ql-formats" *ngIf="!isExternalEvent">
                <select class="ql-header">
                  <option value="1"></option>
                  <option value="2"></option>
                  <option value="3"></option>
                  <option value="4"></option>
                  <option value="5"></option>
                  <option value="6"></option>
                  <option value="false" selected></option>
                </select>
                <button type="button" class="ql-bold" aria-label="Bold"></button>
                <button type="button" class="ql-italic" aria-label="Italic"></button>
                <button type="button" class="ql-underline" aria-label="Underline"></button>
                <button type="button" class="ql-strike" aria-label="Strike"></button>
                <button type="button" class="ql-list" value="ordered"></button>
                <button type="button" class="ql-list" value="bullet"></button>
              </span>
            </ng-template>
          </p-editor>
        </div>
        <div class="field">
          <label for="description">Etiquetas</label>
          <app-label-input [labels]="this.descriptionForm.get('labels')?.value" 
            (onLabelAdd)="onLabelAdd($event)"
            (onLabelRemove)="onLabelRemove($event)"
            [disabled]="isExternalEvent">
          </app-label-input>
        </div>
        <div class="field flex flex-column">
          <label for="image">Imagen *</label>
          <p-image *ngIf="this.descriptionForm.get('imageUrl').value" 
            [src]="this.descriptionForm.get('imageUrl').value"
            alt="Image" width="250">
          </p-image>
          <p-fileUpload #fubauto *ngIf="!isExternalEvent"
            mode="basic" 
            name="myfile[]" 
            accept="image/*" 
            maxFileSize="1000000"
            [customUpload]="true" 
            (uploadHandler)="myUploader($event)" 
            [auto]="true"
            chooseLabel="Browse">
          </p-fileUpload>
        </div>
        <p-panel
          header="Opciones avanzadas" 
          [toggleable]="true" 
          [collapsed]="true" 
          expandIcon="pi pi-angle-down"
          collapseIcon="pi pi-angle-up">
          <div class="field">
            <label for="name">Contraseña</label>
            <input id="passphrase" formControlName="passphrase" type="text" pInputText
              class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full" />
          </div>
          <div class="field">
            <label for="name">Performer</label>
            <input id="performer" formControlName="performer" type="text" pInputText
              class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full" />
          </div>
        </p-panel>
      </form>
    </ng-template>
    <ng-template pTemplate="footer">
      <div class="grid grid-nogutter justify-content-between">
        <div class="flex-grow-1"></div>
        <div *ngIf="!isExternalEvent">
          <button pButton class="p-button-text" *ngIf="!eventId" 
            [disabled]="!descriptionForm.dirty"
            label="Guardar borrador" 
            (click)="saveDraft()">
          </button>
          <button pButton class="p-button-text" *ngIf="!eventId" 
            [disabled]="!descriptionForm.valid" 
            label="Siguiente"
            (click)="nextPage()" 
            icon="pi pi-angle-right" 
            iconPos="right">
          </button>
          <button pButton class="p-button" *ngIf="eventId" 
            [disabled]="!descriptionForm.valid || !descriptionForm.dirty"
            label="Guardar cambios" 
            (click)="updateEvent()">
          </button>
        </div>
      </div>
    </ng-template>
  </p-card>
</div>