import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { LabelService } from '../../services/label.service';
import { Label } from '../../model/label.interface';

@Component({
  selector: 'app-label-input',
  templateUrl: './label-input.component.html',
  styleUrls: ['./label-input.component.scss']
})
export class LabelInputComponent implements OnInit, OnChanges {
  @Input() labels: string[] = [];
  @Input() disabled: boolean = false;
  @Output() onLabelAdd: EventEmitter<string> = new EventEmitter();
  @Output() onLabelRemove: EventEmitter<string> = new EventEmitter();

  selectedLabel: any = '';
  labelOptions: Label[] = [];
  resolvedLabels: Label[] = [];
  loadingLabels: boolean = false;

  constructor(private labelService: LabelService) { }

  ngOnInit(): void {
    if (!this.disabled) {
      this.labelService.searchLabels('').subscribe((response: Label[]) => {
        this.labelOptions = response;
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['labels'] && changes['labels'].currentValue && changes['labels'].currentValue.length > 0) {
      this.loadingLabels = true;
      this.labelService.resolveLabels(changes['labels'].currentValue).subscribe((response: Label[]) => {
        this.resolvedLabels = response;
        this.loadingLabels = false;
      }, () => this.loadingLabels = false);
    }

    // Handle disabled state changes
    if (changes['disabled'] && changes['disabled'].currentValue) {
      this.selectedLabel = '';
    }
  }

  searchLabel($event: any): void {
    if (this.disabled) return;

    const query = $event.filter;
    if (!query || query.length < 3) return;

    this.labelService.searchLabels(query).subscribe((response: Label[]) => {
      this.labelOptions = response;
      if (query && query !== '' && response.map(label => label.value).findIndex(value => value === query) === -1) {
        this.labelOptions.push({ tenantId: 'create', id: 'create', value: `+ Crear '${query}'` });
      }
    });
  }

  addLabel($event: any): void {
    if (this.disabled) return;

    if ($event.value.id !== 'create') {
      this.onLabelAdd.emit($event.value.id);
      this.resolvedLabels.push(this.labelOptions.find(label => label.id === $event.value.id));
    } else {
      let newLabelValue = $event.value.value.split("'")[1];
      this.labelService.createLabel(newLabelValue).subscribe((label: Label) => {
        this.onLabelAdd.emit(label.id);
        this.resolvedLabels.push(label);
      });
    }
    
    // Reset selection after adding
    this.selectedLabel = '';
  }

  removeLabel($event: Label): void {
    if (this.disabled) return;
    this.onLabelRemove.emit($event.id);
  }
}