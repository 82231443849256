<!-- parent.component.html -->
<div class="stepsdemo-content">
  <div class="flex flex-row actions-row">
    <button pButton class="p-button-primary cta-button" 
            label="Añadir personalización" 
            icon="pi pi-plus" 
            [disabled]="isExternalEvent"
            (click)="addExperience()"></button>
  </div>
  <p-card>
    <ng-template pTemplate="title">
      Personalización
      <span *ngIf="isExternalEvent" class="text-sm text-gray-500 ml-2">
        (Evento externo - Solo lectura)
      </span>
    </ng-template>
    <ng-template pTemplate="subtitle">
      Configura las opciones de personalización que tendrá el asistente. Es hora de echar a volar la imaginación.
    </ng-template>
    <ng-template pTemplate="content">
      <div class="flex flex-column" *ngIf="experiences && experiences.length > 0; else noExperiences">
        <div *ngFor="let experience of experiences">
          <app-experience-item [eventId]="eventId" 
                             [experience]="experience" 
                             (onUpdate)="updateExperience($event)"
                             (onRemove)="removeExperienceFromList($event)" 
                             [providerEventId]="providerEventId"></app-experience-item>
        </div>
      </div>
      <ng-template #noExperiences>
        <p>No hay personalización</p>
      </ng-template>
    </ng-template>
    <ng-template pTemplate="footer">
      <div class="grid grid-nogutter justify-content-between"></div>
    </ng-template>
  </p-card>
</div>

<p-sidebar [(visible)]="display" position="right" [baseZIndex]="10000" styleClass="custom-sidebar">
  <ng-template pTemplate="header">
    <h3 *ngIf="experienceForm.get('id').value===null; else updateExperienceTitle">Añadir personalización</h3>
    <ng-template #updateExperienceTitle>
      <h3>Actualizar personalización</h3>
    </ng-template>
  </ng-template>
  <form [formGroup]="experienceForm">
    <section>
      <h4>Datos básicos</h4>
      <p class="sub">Completa los datos básicos de esta entrada</p>
      <div class="field">
        <label for="name">Nombre *</label>
        <input id="name" 
               formControlName="name" 
               type="text" 
               pInputText
               [disabled]="isExternalEvent"
               class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full" />
        <small *ngIf="
            this.experienceForm.get('name')?.invalid &&
            (this.experienceForm.get('name')?.dirty ||
              this.experienceForm.get('name')?.touched)
          " id="name-help" class="p-error block">El nombre es obligatorio</small>
      </div>
      <div class="field">
        <label for="itemDescription">Descripción *</label>
        <p-editor formControlName="description" 
                  [style]="{ height: '200px' }"
                  [readonly]="isExternalEvent">
          <ng-template pTemplate="header">
            <span class="ql-formats" [class.disabled]="isExternalEvent">
              <select class="ql-header" [attr.disabled]="isExternalEvent">
                <option value="1"></option>
                <option value="2"></option>
                <option value="3"></option>
                <option value="4"></option>
                <option value="5"></option>
                <option value="6"></option>
                <option value="false" selected></option>
              </select>
              <button type="button" class="ql-bold" [attr.disabled]="isExternalEvent" aria-label="Bold"></button>
              <button type="button" class="ql-italic" [attr.disabled]="isExternalEvent" aria-label="Italic"></button>
              <button type="button" class="ql-underline" [attr.disabled]="isExternalEvent" aria-label="Underline"></button>
              <button type="button" class="ql-strike" [attr.disabled]="isExternalEvent" aria-label="Strike"></button>
              <button type="button" class="ql-list" [attr.disabled]="isExternalEvent" value="ordered"></button>
              <button type="button" class="ql-list" [attr.disabled]="isExternalEvent" value="bullet"></button>
            </span>
          </ng-template>
        </p-editor>
        <small *ngIf="
            this.experienceForm.get('description')?.invalid &&
            (this.experienceForm.get('description')?.dirty ||
              this.experienceForm.get('description')?.touched)
          " id="name-help" class="p-error block">La descripción es obligatoria</small>
      </div>
      <div class="field flex flex-column">
        <label for="image">Imagen del complemento *</label>
        <app-image-upload [url]="this.experienceForm.get('images')?.value" 
                         [multiple]="true" 
                         alt="Image" 
                         [disabled]="isExternalEvent"
                         recommendedHeight="100px" 
                         recommendedProp="1:1" 
                         (onUpload)="onUpload($event, 'product')"
                         (onRemove)="removeImage($event, 'product')"></app-image-upload>
      </div>
      <div class="field flex flex-column">
        <label for="image">Sponsor</label>
        <app-image-upload [url]="[this.experienceForm.get('sponsor').get('image').value]" 
                         [multiple]="false" 
                         alt="Image"
                         [disabled]="isExternalEvent" 
                         recommendedHeight="100px" 
                         recommendedProp="1:1" 
                         (onUpload)="onUpload($event, 'sponsor')"
                         (onRemove)="removeImage($event, 'sponsor')"></app-image-upload>
      </div>
    </section>
    <section>
      <h4>Venta</h4>
      <p class="sub">Datos de venta de la entrada</p>
      <div class="field">
        <label for="regularPrice">Precio *</label>
        <app-price-input [defaultPrice]="defaultPrice" 
                        [includeTaxes]="includeTaxes" 
                        [fee]="ticketFee" 
                        tax="21" 
                        [disabled]="isExternalEvent"
                        (onPriceChange)="onExperiencePriceChange($event)"></app-price-input>
        <small *ngIf="
            this.experienceForm.get('regularPrice')?.invalid &&
            (this.experienceForm.get('regularPrice')?.dirty ||
              this.experienceForm.get('regularPrice')?.touched)
          " id="name-help" class="p-error block">El precio es obligatorio</small>
      </div>
      <div class="field">
        <label for="stock">Unidades *</label>
        <p-inputNumber id="stock" 
                      formControlName="stock"
                      [disabled]="isExternalEvent"
                      class="text-base text-color surface-overlay p-2 appearance-none outline-none focus:border-primary w-full"></p-inputNumber>
      </div>
      <div class="field">
        <p>Selección múltiple</p>
        <div class="flex align-items-center">
          <p-checkbox id="allowMultiple" 
                     [binary]="true" 
                     styleClass="mr-2" 
                     [disabled]="isExternalEvent"
                     formControlName="allowMultiple"></p-checkbox>
          <label for="accept" class="text-900">Permitir seleccionar más de una unidad por token</label>
        </div>
      </div>
    </section>
    <section>
      <h4>Ventajas para fans</h4>
      <p class="sub">Premia a tus fans con ventajas exclusivas</p>
      <div class="field mt-6">
        <label for="fanDiscount">Descuento para fans</label>
        <p-inputNumber id="fanDiscount" 
                      formControlName="fanDiscount" 
                      suffix="%" 
                      [disabled]="isExternalEvent"
                      class="text-base text-color surface-overlay p-2 appearance-none outline-none focus:border-primary w-full"></p-inputNumber>
      </div>
    </section>
    <section>
      <h4>Configuración avanzada</h4>
      <p class="sub">Gestiona opciones de configuración más avanzada</p>
      <div class="field flex flex-column">
        <label for="ticketTypes">Restringir a los tipos de entrada</label>
        <p-multiSelect id="ticketTypes" 
                      [options]="ticketTypes" 
                      optionLabel="name" 
                      optionValue="id"
                      formControlName="ticketTypes" 
                      [disabled]="isExternalEvent"
                      display="chip"
                      emptyMessage="Todos los tipos de entrada"></p-multiSelect>
      </div>
    </section>
  </form>
  <ng-template pTemplate="footer">
    <button pButton *ngIf="!creatingExperience; else createBlocked"
            [disabled]="experienceForm.invalid || !experienceForm.dirty || isExternalEvent" 
            class="p-button-success"
            [label]="experienceForm.get('id').value===null ? 'Añadir personalización' : 'Actualizar personalización'"
            (click)="createExperience()"></button>
    <ng-template #createBlocked>
      <button pButton 
              [disabled]="true" 
              class="p-button-success"
              [label]="experienceForm.get('id').value===null ? 'Añadir personalización' : 'Actualizar personalización'"
              icon="pi pi-spin pi-spinner" 
              iconPos="right"></button>
    </ng-template>
  </ng-template>
</p-sidebar>