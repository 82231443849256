import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { EventTickets } from '../../model/event-detail.interface';
import { EventService } from '../../service/event.service';
import { DateUtil } from "../../../shared/util/DateUtil";

@Component({
  selector: 'app-ticket-item',
  templateUrl: './ticket-item.component.html',
  styleUrls: ['./ticket-item.component.scss']
})
export class TicketItemComponent {
  @Input() index: number;
  @Input() eventId: string;
  @Input() ticket: EventTickets;
  @Input() isExternalEvent: boolean = false;
  @Output() onUpdate: EventEmitter<EventTickets> = new EventEmitter();
  @Output() onRemove: EventEmitter<number> = new EventEmitter();

  changeTicketsDialogVisible: boolean = false;
  ticketsAvailableToRemove: number = 0;
  messageDialogVisible: boolean = false;

  constructor(
    private eventService: EventService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService
  ) {}

  editTicket() {
    
    this.onUpdate.emit(this.ticket);
  }

  removeTicket() {
    
    if (this.isExternalEvent) return;

    this.confirmationService.confirm({
      message: '¿Estás seguro de que quieres eliminar el ticket?',
      accept: () => {
        if (this.ticket.id) {
          this.eventService.deleteTicket(this.eventId, this.ticket.id).subscribe(
            () => {
              this.messageService.add({ severity: 'success', summary: 'Ticket borrado correctamente', detail: '' });
              this.onRemove.emit(this.index);
            },
            () => {
              this.messageService.add({
                severity: 'error',
                summary: 'Ha ocurrido un error',
                detail: 'Vuelve a intentarlo en unos minutos'
              });
            }
          );
        } else {
          this.onRemove.emit(this.index);
        }
      }
    });
  }

  handleToggleChange(isActive: boolean): void {

    if (this.isExternalEvent) return;

    this.eventService.changeTicketStatus(this.eventId, this.ticket.id, isActive).subscribe(
      () => {
        this.messageService.add({ 
          severity: 'success', 
          summary: 'Estado actualizado correctamente', 
          detail: '' 
        });
      },
      () => {
        this.messageService.add({
          severity: 'error',
          summary: 'Ha ocurrido un error',
          detail: 'Vuelve a intentarlo en unos minutos'
        });
        this.ticket.active = !isActive;
      }
    );
  }

  getLocalDate(date: Date): Date | undefined {
    return date ? DateUtil.fromUTC(date) : undefined;
  }

  showChangeTicketsDialog(): void {

    if (this.isExternalEvent) return;

    this.eventService.getAvailableTicketsToRemove(this.eventId, this.ticket.id).subscribe(
      (availableTickets: number) => {
        this.ticketsAvailableToRemove = availableTickets;
        this.changeTicketsDialogVisible = true;
      },
      () => {
        this.ticketsAvailableToRemove = 0;
        this.changeTicketsDialogVisible = true;
      }
    );
  }

  applyTicketChanges(difference: number): void {
    if (difference > 0) {
      this.eventService.addTickets(this.eventId, this.ticket.id, { number: difference }).subscribe(
        () => {
          this.messageService.add({ severity: 'success', summary: 'Entradas añadidas correctamente', detail: '' });
          this.ticket.ticketsNumber += difference;
          this.changeTicketsDialogVisible = false;
        },
        () => {
          this.messageService.add({
            severity: 'error',
            summary: 'Ha ocurrido un error',
            detail: 'Vuelve a intentarlo en unos minutos'
          });
        }
      );
    } else if (difference < 0) {
      this.eventService.removeTickets(this.eventId, this.ticket.id, { number: -difference }).subscribe(
        () => {
          this.messageService.add({ severity: 'success', summary: 'Entradas eliminadas correctamente', detail: '' });
          this.ticket.ticketsNumber += difference;
          this.changeTicketsDialogVisible = false;
        },
        () => {
          this.messageService.add({
            severity: 'error',
            summary: 'Ha ocurrido un error',
            detail: 'Vuelve a intentarlo en unos minutos'
          });
        }
      );
    } else {
      this.changeTicketsDialogVisible = false;
    }
  }

  createCampaign(): void {
    this.messageDialogVisible = true;
  }

  onMessageDialogHide(): void {
    this.messageDialogVisible = false;
  }
}