import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { FormPart } from '../../model/form-part.interface';
import { EventService } from '../../service/event.service';

@Component({
  selector: 'app-step-description',
  templateUrl: './step-description.component.html',
  styleUrls: ['./step-description.component.scss']
})
export class StepDescriptionComponent implements OnInit, OnChanges {
  personalInformation: any;
  submitted: boolean = false;
  uploadedFiles: any[] = [];
  
  @Input() eventId: string;
  @Input() providerEventId: string;
  @Input() data: any;
  @Input() formValue: any;
  
  @Output() next: EventEmitter<FormPart> = new EventEmitter();
  @Output() save: EventEmitter<FormPart> = new EventEmitter();

  descriptionForm = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.minLength(10), Validators.maxLength(80)]),
    description: new FormControl(''),
    imageUrl: new FormControl('', []),
    passphrase: new FormControl('', []),
    labels: new FormControl([], []),
    performer: new FormControl('', [])
  });

  get isExternalEvent(): boolean {
    return !!this.providerEventId;
  }

  constructor(private eventService: EventService, private messageService: MessageService) { }

  ngOnInit() {

    if (this.isExternalEvent) {
      this.descriptionForm.disable();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data'] && changes['data'].currentValue) {
      this.descriptionForm.patchValue(this.data);
    }
    
    if (changes['formValue'] && changes['formValue'].currentValue) {
      this.descriptionForm.patchValue(changes['formValue'].currentValue);
    }

    if (changes['providerEventId']) {
      if (this.isExternalEvent) {
        this.descriptionForm.disable();
      } else {
        this.descriptionForm.enable();
      }
    }
  }

  nextPage() {
    if (this.isExternalEvent) return;
    
    this.next.emit({
      next: 1,
      name: 'description',
      data: this.descriptionForm
    });
  }

  myUploader(files: any) {
    if (this.isExternalEvent) return;

    const file = files.files[0];
    const imageName: string = self.crypto.randomUUID();
    const mimeType = file.type;

    this.eventService.getSignedUrl(imageName, mimeType).subscribe(signedUrl => {
      this.eventService.uploadImage(signedUrl.signedUrl, mimeType, file).subscribe(response => {
        if (response.status !== undefined && response.status == 200) {
          this.uploadedFiles.push(file);
          this.descriptionForm.patchValue({ imageUrl: signedUrl.destinationUrl });
          this.descriptionForm.markAsDirty();
        }
      });
    });
  }

  updateEvent(): void {
    if (this.isExternalEvent) return;

    const value = {
      description: this.descriptionForm.value
    };

    this.eventService.updateEvent(this.eventId, value, 'description').subscribe(() => {
      this.messageService.add({ severity: 'success', summary: 'Guardado con éxito', detail: '' });
      this.descriptionForm.markAsPristine();
    }, () => this.messageService.add({ 
      severity: 'error', 
      summary: 'Ha ocurrido un error', 
      detail: 'Revisa los datos y vuelve a intentarlo' 
    }));
  }

  saveDraft(): void {
    if (this.isExternalEvent) return;
    
    this.save.emit({
      next: 0,
      name: 'description',
      data: this.descriptionForm
    });
  }

  onLabelAdd($event: string): void {
    if (this.isExternalEvent) return;

    let labels = this.descriptionForm.get('labels').value;
    if (labels === undefined || labels === null) {
      labels = [];
    }
    labels.push($event);
    this.descriptionForm.patchValue({ labels: labels });
    this.descriptionForm.markAsDirty();
  }

  onLabelRemove($event: string): void {
    if (this.isExternalEvent) return;

    let labels = this.descriptionForm.get('labels').value;
    if (labels === undefined || labels === null) {
      labels = [];
    }
    const index = labels.indexOf($event);
    labels.splice(index, 1);
    this.descriptionForm.patchValue({ labels: labels });
    this.descriptionForm.markAsDirty();
  }
}