import { Component, OnDestroy, OnInit } from '@angular/core';
import { EventService } from './service/event.service';
import { Event } from './model/event.interface';
import { DateTime, Interval } from 'luxon';
import { ActivatedRoute, Router } from '@angular/router';
import { EventState } from './util/event-state.util';
import { LinkUtils } from './util/link.util';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CreatedEvent } from './model/created-event.interface';
import { PagedResponse } from '../shared/model/paged-response.interface';
import { debounceTime, distinctUntilChanged, Subject, takeUntil } from 'rxjs';

enum EventTimeStatus {
  PAST = 'PAST',
  HAPPENING_NOW = 'HAPPENING_NOW',
  FUTURE = 'FUTURE'
}

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss']
})
export class EventComponent implements OnInit, OnDestroy {
  EventTimeStatus = EventTimeStatus;
  events: Event[] = [];
  selectedEvent: string = '';
  publishing: boolean = false;
  currentView: 'future' | 'past' | 'all' = 'future';

  // Pagination properties
  rowsPerPageOptions = [25, 50, 100];
  totalRecords: number = 0;
  currentPage: number = 0;
  pageSize: number = 25;
  first: number = 0;
  loading: boolean = false;
  skeletonRows = Array(25).fill({});

  // Searching and sorting
  searchTerm: string = '';
  sortField: string = 'venue.startDate';
  sortOrder: number = -1;
  private searchSubject = new Subject<string>();
  private destroy$ = new Subject<void>();

  private initialLoad: boolean = true;

  private readonly SORT_FIELDS = {
    // Display field -> MongoDB field
    'name': 'description.name',
    'location': 'venue.location.name',
    'startDate': 'venue.startDate',
    'state': 'state',
    'tickets': 'ticketsSold',
    'provider': 'provider.name'
  };

  ticketingProviders: any[] = [];
  selectedProviders: string[] = [];

  constructor(
    private eventService: EventService,
    private router: Router,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private route: ActivatedRoute
  ) {
    this.searchSubject.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      takeUntil(this.destroy$)
    ).subscribe(term => {
      this.searchTerm = term;
      this.currentPage = 0;
      this.first = 0;
      
      // Update URL with search params
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {
          view: this.currentView,
          search: term || null,
          page: 0,
          size: this.pageSize,
          sort: this.sortField,
          order: this.sortOrder
        },
        queryParamsHandling: 'merge'
      });
      
      this.loadEvents();
    });
  }

  private readonly sortFieldMap = {
    'name': 'description.name',
    'location': 'venue.location.name',
    'startDate': 'venue.startDate',
    'state': 'state'
  };

  ngOnInit(): void {
    this.loading = true;
    this.route.queryParams.pipe(
      takeUntil(this.destroy$)
    ).subscribe(params => {
      this.currentView = params['view'] || 'future';
      this.currentPage = params['page'] ? +params['page'] : 0;
      this.pageSize = params['size'] ? +params['size'] : 25;
      this.first = this.currentPage * this.pageSize;
      this.searchTerm = params['search'] || '';
   
      // Get sort field from URL or default to startDate
      const paramSortField = params['sort'] || 'venue.startDate';
      
      // Find display field from MongoDB field, or fallback to startDate
      this.sortField = Object.entries(this.SORT_FIELDS)
        .find(([_, value]) => value === paramSortField)?.[0] || 'startDate';
      
      this.sortOrder = params['order'] ? +params['order'] : -1;
      this.loadEvents();
    });

    this.loadTicketingProviders();

  }

  loadTicketingProviders() {
    this.eventService.getValidatedIntegrations().subscribe(
      providers => {
        this.ticketingProviders = providers;
      },
      error => {
        this.messageService.add({ 
          severity: 'error', 
          summary: 'Error', 
          detail: 'No se pudieron cargar los canales de ticketing' 
        });
      }
    );
  }

  syncSelectedProviders() {
    if (this.selectedProviders && this.selectedProviders.length > 0) {
      this.eventService.syncProviders(this.selectedProviders)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          () => {
            this.messageService.add({
              severity: 'success',
              summary: 'Sincronización solicitada',
              detail: 'Los canales se sincronizarán en breve.'
            });
          },
          error => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'No se pudo iniciar la sincronización.'
            });
          }
        );
    } else {
      this.messageService.add({
        severity: 'warn',
        summary: 'Sin selección',
        detail: 'Por favor, seleccione al menos un canal.'
      });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  navigateTo(view: string) {
    this.initialLoad = false;
    this.currentPage = 0;
    this.first = 0;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { 
        view, 
        page: 0,
        search: this.searchTerm || null,
        sort: this.sortField,
        order: this.sortOrder
      },
      queryParamsHandling: 'merge'
    });
  }

  loadEvents(): void {
    this.loading = true;
    const future = this.currentView === 'future';
    const all = this.currentView === 'all' ? true : null;

    const mongoSortField = this.SORT_FIELDS[this.sortField] || this.sortField;

    this.eventService.getEventsPaged(
      future, 
      all, 
      this.currentPage, 
      this.pageSize,
      mongoSortField,
      this.sortOrder,
      this.searchTerm
    ).subscribe((response: PagedResponse<Event>) => {
      this.events = response.content;
      this.totalRecords = response.totalElements;
      this.loading = false;

      // Check if it's the initial load, current view is 'future', and no events are present
      if (this.initialLoad && this.currentView === 'future' && this.events.length === 0) {
        this.initialLoad = false; // Prevent further automatic switches
        this.navigateTo('all');    // Switch to 'all' view
        }

    }, () => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to load events.' });
      this.loading = false;
    });
  }

  onSort(event: any): void {
    // Get MongoDB field name or fallback to the original field
    const mongoField = this.SORT_FIELDS[event.field] || event.field;
    
    // Update URL with MongoDB field
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        sort: mongoField,
        order: event.order
      },
      queryParamsHandling: 'merge'
    });

    // Keep display field for table
    this.sortField = event.field;
    this.sortOrder = event.order;

    this.loadEvents();
  }

  onSearch(event: any): void {
    this.searchSubject.next(event.target.value);
  }

  onPageChange(event: any): void {
    this.currentPage = event.first / event.rows;
    this.pageSize = event.rows;
    this.first = event.first;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { page: this.currentPage, size: this.pageSize },
      queryParamsHandling: 'merge'
    });
  }

  handleChange(eventId: string, event: any): void {
    event.originalEvent.stopPropagation();

    let newState = 'ACTIVE';
    const index = this.events.findIndex(evt => evt.id === eventId);

    if (this.events[index].state === 'ACTIVE') {
      newState = 'PAUSED';
      this.events[index].active = false;
    } else {
      newState = 'ACTIVE';
      this.events[index].active = true;
    }

    this.events[index].state = newState;
    this.eventService.changeStatus(eventId, newState).subscribe(response => {
      this.messageService.add({ severity: 'success', summary: 'Estado actualizado', detail: 'El estado del evento ha sido actualizado.' });
    }, () => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'No se pudo actualizar el estado del evento.' });
    });
  }

  getState(state: string): string {
    return EventState.getState(state);
  }

  getSeverity(state: string): string {
    return EventState.getSeverity(state);
  }

  openEvent($event): void {
    this.router.navigate(['/events', $event.data.id]);
  }

  copyLink(url: string): void {
    LinkUtils.copy(url);
    this.messageService.add({ severity: 'success', summary: 'Enlace copiado', detail: 'El enlace ha sido copiado al portapapeles.' });
  }

  publish(eventId: string): void {
    this.publishing = true;
    this.eventService.publish(eventId).subscribe(() => {
      this.messageService.add({ severity: 'success', summary: 'Evento publicado', detail: 'La experiencia se ha publicado correctamente.' });
      const index = this.events.findIndex(event => event.id === eventId);
      if (index > -1) {
        this.events[index].state = 'ACTIVE';
        this.events[index].active = true;
      }
      this.publishing = false;
    }, () => {
      this.messageService.add({ severity: 'error', summary: 'Error al publicar', detail: 'Por favor, revisa los datos e inténtalo de nuevo.' });
      this.publishing = false;
    });
  }

  getLocalDate(eventDate: string): string {
    return DateTime.fromISO(eventDate)
      .setLocale('es')
      .toLocaleString(DateTime.DATETIME_SHORT);
  }

  formatAddress(event: any): string {
    if (event === undefined) return '';

    if (event?.location?.name && event?.location?.addressComponents.length > 0) {
      const locality = event?.location.addressComponents.find(x => x.types.includes('locality'));
      return `${event?.location.name}, ${locality?.short_name ? locality?.short_name : ''}`;
    } else {
      return event?.location?.formattedAddress || '';
    }
  }

  clone(id: string): void {
    this.confirmationService.confirm({
      message: '¿Seguro/a que quieres duplicar la experiencia?',
      header: 'Confirmación',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.eventService.clone(id).subscribe((response: CreatedEvent) => {
          this.messageService.add({ severity: 'success', summary: 'Experiencia duplicada', detail: 'La experiencia se ha duplicado correctamente.' });
          this.loadEvents();
        }, () => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'No se pudo duplicar la experiencia.' });
        });
      }
    });
  }
  
  getEventTimeStatus(event: Event): EventTimeStatus {
    if (!event.startDate || !event.endDate) {
      return EventTimeStatus.FUTURE;
    }
    
    const now = DateTime.local();
    const startDate = DateTime.fromISO(event.startDate);
    const endDate = DateTime.fromISO(event.endDate);
  
    if (endDate < now) {
      return EventTimeStatus.PAST;
    }
    
    if (Interval.fromDateTimes(startDate, endDate).contains(now)) {
      return EventTimeStatus.HAPPENING_NOW;
    }
    
    return EventTimeStatus.FUTURE;
  }

}
