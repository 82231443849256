import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, ControlContainer, FormArray, FormBuilder, FormGroup, FormGroupDirective, ValidatorFn, Validators } from '@angular/forms';

@Component({
  selector: 'app-step-tickets-assignation',
  templateUrl: './step-tickets-assignation.component.html',
  styleUrls: ['./step-tickets-assignation.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class StepTicketsAssignationComponent implements OnInit {

  @Input() disabled: boolean = false;

  parentForm!: FormGroup;

  types = [
    { name: 'Texto corto', code: 'text' },
    { name: 'Texto largo', code: 'textarea' },
    { name: 'Checkbox', code: 'checkbox' },
    { name: 'Selección única', code: 'single-select' },
    { name: 'Selección múltiple', code: 'multi-select' }
  ];

  constructor(private parent: FormGroupDirective, private fb: FormBuilder) { }



  ngOnInit(): void {
    this.parentForm = this.parent.form;
    this.setupFieldDependencies();
    this.addSelectFieldsValidator();

    if (this.disabled) {
      (this.parentForm.get('assignation.fields') as FormGroup).disable();
      (this.parentForm.get('assignation.additional') as FormArray).disable();
    }
  }

  createFieldGroup(): FormGroup {
    return this.fb.group({
      name: ['', Validators.required],
      type: ['text', Validators.required],
      description: [''],
      required: [false],
      options: this.fb.array([])
    });
  }

  setupFieldDependencies() {
    const fieldsGroup = this.parentForm.get('assignation.fields') as FormGroup;
    Object.keys(fieldsGroup.controls).forEach(key => {
      const field = fieldsGroup.get(key) as FormGroup;
      field.get('enabled')?.valueChanges.subscribe(enabled => {
        if (enabled) {
          field.get('required')?.enable();
        } else {
          field.get('required')?.disable();
          field.get('required')?.setValue(false);
        }
      });
    });
  }

  addField() {
    if (this.disabled) return;
    const newField = this.createFieldGroup();
    this.fields.push(newField);
    this.parentForm.markAsDirty();
  }

  deleteAdditionalField(index: number) {
    if (this.disabled) return;
    this.fields.removeAt(index);
  }

  get fields() {
    return (this.parentForm.get('assignation') as FormGroup).get('additional') as FormArray;
  }

  getFieldLabel(field: string): string {
    const labels: { [key: string]: string } = {
      name: 'Nombre',
      surname: 'Apellido',
      phone: 'Teléfono',
      fiscalId: 'Identificación fiscal (DNI, NIE...)',
      genre: 'Sexo',
      birth: 'Fecha de nacimiento'
    };
    return labels[field] || field;
  }

  getOptions(formGroup: FormGroup): FormArray {
    console.log(formGroup.get('options'));
    return formGroup.get('options') as FormArray;
  }

  addOption(fieldGroup: FormGroup) {
    if (this.disabled) return;
    const options = fieldGroup.get('options') as FormArray;
    options.push(this.fb.control('', Validators.required));
  }

  deleteOption(fieldGroup: FormGroup, index: number) {
    if (this.disabled) return;
    const options = fieldGroup.get('options') as FormArray;
    options.removeAt(index);
  }

  addSelectFieldsValidator() {
    const additionalFields = this.parentForm.get('assignation.additional') as FormArray;
    additionalFields.valueChanges.subscribe(() => {
      additionalFields.controls.forEach((field: AbstractControl) => {
        field.setValidators(this.selectFieldValidator());
        field.updateValueAndValidity({ emitEvent: false });
      });
    });
  }

  selectFieldValidator(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      const type = control.get('type')?.value;
      const options = control.get('options') as FormArray;
      
      if ((type === 'single-select' || type === 'multi-select') && (!options || options.length === 0)) {
        return { 'noOptions': true };
      }
      return null;
    };
  }

}
