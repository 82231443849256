<div class="field flex flex-row">
  <div class="ml-3">
    <p-fileUpload
      #fubauto
      mode="basic"
      name="myfile[]"
      accept="image/*"
      maxFileSize="1000000"
      [customUpload]="true"
      (uploadHandler)="myUploader($event)"
      [auto]="true"
      chooseLabel="Browse"
      [disabled]="disabled || uploading"
      [multiple]="multiple"
    >
    </p-fileUpload>
    <p class="sub">
      <span *ngIf="recommendedProp">Proporción recomendada {{ recommendedProp }}.</span>
    </p>
    <p class="sub">
      <span *ngIf="recommendedHeight">Altura recomendada {{ recommendedHeight }}.</span>
    </p>
  </div>
  <div class="flex flex-row ml-3">
    <div *ngFor="let image of url" class="flex flex-column ml-3">
      <p-image *ngIf="image" [src]="image" [alt]="alt" width="250"></p-image>
      <p-button
        icon="pi pi-trash"
        styleClass="p-button-rounded p-button-danger p-button-text"
        label="Eliminar"
        [disabled]="disabled"
        (click)="removeImage(image)"
      ></p-button>
    </div>

    <div
      class="image-placeholder flex align-items-center justify-content-center"
      *ngIf="!url || url.length === 0"
    >
      No hay imagen
    </div>
  </div>
</div>