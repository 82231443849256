import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { User } from './model/user.interface';
import { UserService } from './service/user.service';
import { Table } from 'primeng/table';
import { LazyLoadEvent } from 'primeng/api';
import { PagedResponse } from '../shared/model/paged-response.interface';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
})
export class UserComponent implements OnInit {
  states: any[] = [
    { id: 'ACTIVE', name: 'Activo' },
    { id: 'INVITED', name: 'Invitado' },
    { id: 'INVITED_BY_ADMIN', name: 'Invitado' },
    { id: 'REGISTERED', name: 'Registrado' },
  ];
  users: User[] = [];
  items: any[];

  displayCreateDialog: boolean = false;
  displayImportDialog: boolean = false;

  // Pagination properties
  totalRecords: number = 0;
  currentPage: number = 0;
  pageSize: number = 25;
  first: number = 0;
  loading: boolean = false;
  rowsPerPageOptions: number[] = [25, 50, 100];
  skeletonRows = Array(25).fill({});

  // Search properties
  searchTerm: string = '';
  searchSubject = new Subject<string>();
  sortField: string = 'email';
  sortOrder: number = 1;

  constructor(
    private userService: UserService, 
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.searchSubject.pipe(
      debounceTime(300),
      distinctUntilChanged()
    ).subscribe(term => {
      this.searchTerm = term;
      this.loadUsers({
        first: 0,
        rows: this.pageSize,
        sortField: this.sortField,
        sortOrder: this.sortOrder
      });
    });
  }

  ngOnInit(): void {
    this.displayImportDialog = false;
    this.displayCreateDialog = false;

    this.route.queryParams.subscribe(params => {
      this.currentPage = params['page'] ? +params['page'] : 0;
      this.pageSize = params['size'] ? +params['size'] : 25;
      this.first = this.currentPage * this.pageSize;
      this.sortField = params['sort'] || 'email';
      this.sortOrder = params['order'] ? +params['order'] : 1;
      this.searchTerm = params['search'] || '';      
      
      const event: LazyLoadEvent = {
        first: this.first,
        rows: this.pageSize,
        sortField: this.sortField,
        sortOrder: this.sortOrder        
      };
      this.loadUsers(event);
    });

    this.initializeSpeedDialItems();
  }

  onSearch(event: any): void {
    const term = event.target.value;
    this.searchSubject.next(term);
  }

  private initializeSpeedDialItems(): void {
    this.items = [
      {
        label: 'Importar fans',
        icon: 'pi pi-upload',
        command: () => {
          this.displayImportDialog = true;
        },
        tooltipOptions: {
          tooltipLabel: 'Importar fans',
          tooltipPosition: 'left'
        }
      },
      {
        label: 'Crear',
        icon: 'pi pi-plus',
        command: () => {
          this.displayCreateDialog = true;
        },
        tooltipOptions: {
          tooltipLabel: 'Crear fan',
          tooltipPosition: 'left'
        }
      }
    ];
  }

  loadUsers(event: LazyLoadEvent) {
    this.loading = true;
    
    const page = event.first! / event.rows!;
    const size = event.rows!;
    const sort = event.sortField || this.sortField;
    const order = event.sortOrder || this.sortOrder;    

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { 
        page, 
        size, 
        sort,
        order,
        search: this.searchTerm || null
      },
      queryParamsHandling: 'merge'
    });

    this.sortField = sort;
    this.sortOrder = order;    

    this.userService.getUsers(page, size, sort, order, this.searchTerm).subscribe({
      next: (response: PagedResponse<User>) => {
        this.users = response.content;
        this.totalRecords = response.totalElements;
        this.loading = false;
      },
      error: (error) => {
        console.error('Error fetching users:', error);
        this.loading = false;
      }
    });
  }

  navigateToUser(userId: string): void {
    if (userId) {
      this.router.navigate(['/users', userId]);
    }
  }

  showImportDialog(): void {
    this.displayImportDialog = true;
  }

  hideDialog(dialogId: string): void {
    if (dialogId === 'import') {
      this.displayImportDialog = false;
    }
    if (dialogId === 'create') {
      this.displayCreateDialog = false;
    }
  }

  getFullName(name: string, surname1: string, surname2: string): string {
    let fullName = name == null ? '' : name;
    fullName += surname1 != null ? ' ' + surname1 : '';
    fullName += surname2 != null ? ' ' + surname2 : '';
    return fullName.trim() === '' ? '-' : fullName;
  }

  getState(state: string): string {
    switch (state) {
      case 'ACTIVE':
        return 'Activo';
      case 'INVITED':
      case 'INVITED_BY_ADMIN':
        return 'Invitado';
      case 'REGISTERED':
        return 'Registrado';
      default:
        return '';
    }
  }

  clear(table: Table) {
    table.clear();
  }
}