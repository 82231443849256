<div class="label-input-container">
    <div class="dropdown-container">
        <p-dropdown 
            [options]="labelOptions" 
            [(ngModel)]="selectedLabel" 
            optionLabel="value" 
            [filter]="true"
            filterBy="value" 
            (onFilter)="searchLabel($event)" 
            (onChange)="addLabel($event)" 
            placeholder="Añadir etiqueta"
            [disabled]="disabled"
            class="mr-1">
        </p-dropdown>
        <p-progressSpinner [hidden]="!loadingLabels" styleClass="custom-spinner"></p-progressSpinner>
    </div>
    <div class="tags-container" [hidden]="loadingLabels">
        <p-chip 
            *ngFor="let label of resolvedLabels" 
            [label]="label.value" 
            [removable]="!disabled"
            (onRemove)="removeLabel(label)">
        </p-chip>
    </div>
</div>